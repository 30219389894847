import Router from './Router'
import Element from './functions/Element'

class App {
  constructor(){
    this.name = "#WebApp"
    this.version = "1.0.0"
    this.apiBase = "https://ps.hashtag-webapp.com" 
    this.rootEl = document.getElementById("root")
    this.clientCode = "wa-40430" 
    // Client code is used for automated contact emails
      // New clients are added to the Mailrecipient Object on the Backend
      // cl = client, wa = project code, 4 year 2024, 04 = April, 30 = date
  }

  // App.clientCode 
  // Work Version
  // http://localhost:3000
  // https://ps.hashtag-webapp.com' 

  init() { 
    Element.removeAllChildren(this.rootEl)
    // console.log('app init')
    Router.init()  
  } 

  resizeListener(){
    document.body.style.height = window.innerHeight + 'px';  
    const footer = document.querySelector('main-app-footer');
    this.setSiteWidth(footer)
    // above solution not working for resizing the header 
        // - possibly because internal styles need adjusting also
    this.repaintHeader()
    this.equalizeHomeDivHeights()
  }

  isMobile(){
    let viewportWidth = window.innerWidth
    if(viewportWidth <= 768){ return true }
    return false
  }

  setSiteWidth(el){
    const width = this.rootEl.clientWidth
    el.style.width = width + "px"
  }

  repaintHeader(){
    const oldHeader = document.querySelector('main-app-header');
    const newHeader = document.createElement('main-app-header')
    oldHeader.replaceWith(newHeader) ;
  }


  equalizeHomeDivHeights() {
    if(this.isMobile === true){return}
    const divs = document.querySelectorAll('.float-text p')
    //reset to to auto first
    divs.forEach(div => {div.style.height = "auto"})
    let biggestHeight = 0
    divs.forEach(div => { if (div.clientHeight > biggestHeight){biggestHeight = div.clientHeight}})
    divs.forEach(div => {div.style.height = biggestHeight + "px"})
  }



  
}

export default new App()