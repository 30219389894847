import {LitElement, html, css} from 'lit'
import {anchorRoute} from '../Router'


customElements.define('c-success', class Features extends LitElement {
  constructor(){
    super()    
  } 

  firstUpdated(){
    super.firstUpdated()
  }

  backtoMain(){
    console.log('back to main')
    const mainTab = document.getElementById('main-tab')
    const statusTab = document.getElementById('status-tab')
    mainTab.classList.remove('hidden')
    statusTab.classList.add('hidden')
  }

  static styles = css`
      * { box-sizing: border-box; margin: 0px; padding: 0px; font-size: 1em; }
      .outer-box { text-align: center; }
      .mi-text { margin: 20px auto;  width: 100%; 
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; }
      .success{font-size: 1.3em;}
      .cls-1{fill:var(--success-color);}
      .cls-2{fill:#fff;}
      #tick{ width: 100px; }

      .c-btn {
        text-decoration: none;
        display: block;
        text-align: center;
        border-radius: 500px;
        outline: none;
        cursor: pointer;
        font-size: 0.9em;
        width: 250px;
        padding: .5em;
        margin: 20px auto;
      }

      .action-btn {
        background-color: var(--action-color);
        color: white;
        border: none;
      }

      @media all and (max-width: 450px){ 
        .action-btn { width: 180px; padding: .3em; }
        .mi-text { width: 90%; }
        #tick{ width: 70px; }
      }
  `

  render(){    
    return html`
      <div class="outer-box">
        <p class="mi-text success">Success</p>
        <svg id="tick" alt="Success Tick" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56.3404 56.3404">
          <g id="Layer_2" data-name="Layer 2">
            <g id="Icons">
            <circle class="cls-1" cx="28.1702" cy="28.1702" r="28.1702"/>
            <path class="cls-2" d="M23.8491,46.9008a3.5027,3.5027,0,0,1-2.5572-1.11L11.1684,34.9545a3.5,3.5,0,0,1,5.1152-4.7793l7.09,7.5889L39.6923,13.0122a3.5,3.5,0,0,1,5.8438,3.8535L26.7714,45.3276a3.4975,3.4975,0,0,1-2.6274,1.5605C24.0459,46.8969,23.9472,46.9008,23.8491,46.9008Z"/></g></g>
        </svg>
        <br><br>
        <p class="mi-text"> Thank you, we will try to respond soon.</p>
        <br><br>
        <a href="/" class="c-btn action-btn" @click="${anchorRoute}">
          Home
        </a>  
       </div>

    `
  }
})
