import {LitElement, html, css} from 'lit'
import {anchorRoute} from '../Router'


customElements.define('c-error', class Features extends LitElement {
  constructor(){
    super()    
  } 

  firstUpdated(){
    super.firstUpdated()
  }

  backToMain(){
    const mainTab = document.getElementById('main-tab')
    const statusTab = document.getElementById('status-tab')
    mainTab.classList.remove('hidden')
    statusTab.innerHTML = ''
  }

  static styles = css`
      * { box-sizing: border-box; margin: 0px; padding: 0px; font-size: 1.1em; }
      .outer-box { text-align: center; }
      .mi-text { margin: 30px auto;  width: 100%; font-size: 1.1em; 
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; }
      .exclaim{ color: var(--warning-color); font-size: 6em; margin: auto; }

      .c-btn {
        text-decoration: none;
        display: block;
        text-align: center;
        border-radius: 500px;
        outline: none;
        cursor: pointer;
        font-size: 0.8em;
        width: 250px;
        padding: .6em;
        margin: 20px auto;
      }

      .action-btn {
        background-color: var(--action-color);
        color: white;
        border: none;
      }

      .secondary-btn {
        background-color: white;
        color:  var(--action-color);
        border: solid 1px var(--action-color);
      }

      @media all and (max-width: 450px){ 
        .action-btn, .secondary-btn  {
          width: 200px;
          padding: .3em;
        }
        .exclaim { font-size: 6em; }
      }
  `

  render(){    
    return html`
      <div class="outer-box">
        <p class="mi-text">Something went wrong</p>
        <p class="exclaim">!</p>
        <button class="c-btn action-btn" @click=${this.backToMain.bind(this)}>Back to Message</button>
        <a href="/" class="c-btn secondary-btn" @click="${anchorRoute}">Home</a>  
      </div>
    `
  }
})
