import {LitElement, html, css} from 'lit'
import {anchorRoute} from '../Router'
import App from '../App'


customElements.define('main-app-footer', class AppFooter extends LitElement {
  constructor(){
    super()    
    this.attachShadow({ mode: "open" })
  }

  firstUpdated(){
    super.firstUpdated()
    this.footer = document.querySelector('main-app-footer')
    App.setSiteWidth(this.footer)
  }

  static styles = css`
      * { box-sizing: border-box; margin: 0px; padding: 0px; }

      footer {
        height: 250px;
        margin-top: 30px;
        padding-top: 100px;
        padding-right: 15px;
        background: var(--brand-gradient);        
        color: white;
        font-weight: 700;
        display: flex; align-items: center; justify-content: center;
        flex-direction: column; 
        position: relative;
      }

      a {
        color: white;
        cursor: pointer;
      }


      #foot-name {
        font-size: 3em;
      }

      nav {
        z-index: 50;
      }

      /* mobile styles */
      @media all and (max-width: 768px){   
        footer {
          height: 200px;
          margin-top: 30px;
          padding-top: 50px;
          font-size: .8em;
        }

      }
  
  `

  render(){    
    return html`

    <footer>
      <p id="foot-name">${App.name}</p>
      <nav>
        <a href="/terms" @click="${anchorRoute}">Terms of Service</a> | 
        <a href="/privacy" @click="${anchorRoute}">Privacy Policy</a>
      </nav>
    </footer>
  
    `
  }
})
