import App from '../App'
import {render, html} from 'lit/html.js'
import { anchorRoute } from '../Router'

class TemplateView {
  init(){    
    document.title = 'Test Pages'    
    this.render() 
  }



  render(){
    const template = html`   
      <style>


        #test-div {
          margin-top: 30vh;
          width: 70vw;
          padding: 1em;
          border: grey solid 1px;
          /* background: black; */
        }


      </style>

    
      <body id="contentpanelid" class="content-panel">  
        <main-app-header pagename="${document.title}"></main-app-header>

        <main class="flex-center">
          
          <div id="test-div">

          <html>
            <table cellpadding="0" cellspacing="0" style="width:100%;max-width:800px;margin:0 auto;">
              <tbody>
                <tr>
                  <!-- <img src="cid:pscontentbanner2050n4" alt="You have a new contact message" style="display: block;width:100%;margin-bottom:2em;"/> -->
                  <img src="/images/wa-email-banner.png" alt="You have a new contact message" style="width:100%;" >
                </tr>

                <tr>
                <h3 style="padding:0.5em;color:#5600E9;">
                  Contact message via: req.body.website 
                </h3>
                </tr>
                <tr>
                <div style="color: #9200F1;font-family: sans-serif;width:80px;margin-top: 1em;display:inline-block;margin-left: 2em;"><strong>From: </strong></div>
                <div style="min-width:150px; display: inline-block;margin-left: 2em;max-width:90%;">
                  <p style="color:rgb(128, 128, 128);">
                    req.body.firstName
                  </p>
                </div><br>
                <div style="width: 90%;border-top: solid 1px rgb(219, 217, 217);margin: 0.5em 1em;"></div>

                <div style="color: #9200F1;font-family: sans-serif;width:80px;margin-top: 1em;display:inline-block;margin-left: 2em;"><strong>Email: </strong></div>
                  <div style="min-width:150px; display: inline-block;margin-left: 2em;max-width:90%;">
                  <p style="color:rgb(128, 128, 128);">
                    req.body.userEmail
                  </p>
                </div><br>

                <div style="width: 90%;border-top: solid 1px rgb(219, 217, 217);margin: 0.5em 1em;"></div>

                <div style="color: #9200F1;font-family: sans-serif;width:80px;margin-top: 1em;display:inline-block;margin-left: 2em;"><strong>Message: </strong></div>
                <div style="min-width:150px; display: inline-block;margin-left: 2em;max-width:90%;">
                  <p style="color:rgb(128, 128, 128);">
                    req.body.userMessage 
                  </p>
                </div><br>

                </tr>
                <tr>
                  <div style="width: 90%;border-top: solid 1px rgb(219, 217, 217);margin: 0.5em 1em;"></div>
                
                  <div style="display:block;background:linear-gradient(to top left, #ff00ff, #4600E7);margin-top:2em; text-align:center;padding:1em;">
                    <a href="https://www.hashtag-webapp.com/" style="color:white;font-family:sans-serif; font-weigth: bold;">#WebApp</a>
                  </div>
                
                </tr>
              </tbody>
            </table>
            </html>
          </div>
        
        </main>

        <main-app-footer></main-app-footer>
      </body> 


      
    `

    render(template, App.rootEl)
  }
}

export default new TemplateView()