import {LitElement, html, css} from 'lit'
import {anchorRoute} from '../Router'
import App from '../App'


customElements.define('wa-ads-mobile', class WaAds extends LitElement {
  constructor(){
    super()    
    this.attachShadow({ mode: "open" })
  }

  firstUpdated(){
    super.firstUpdated()
    this.footer = document.querySelector('main-app-footer')
    App.setSiteWidth(this.footer)
  }

  static styles = css`
      * { box-sizing: border-box; margin: 0px; padding: 0px; }

      aside {
        width: auto;
        height: 20vh;
        display: flex; align-items: center; justify-content: center; 
      }

      .flip-container {
        min-width: 250px;
        height: 100%;
        background: var(--grey-100);
        position: relative;
      }

      p {
        right: 0;
        top: -20px;
        position: absolute;
        font-size: .8em;
        opacity: 0.4;
      }

      .ad-holder {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transform-style: preserve-3d;
        transition: all 0.8s ease;
        animation: flip 6s infinite;
      }

      @keyframes flip {
        23% {transform: rotateX(0deg)}
        27%, 73% {transform: rotateX(180deg)}
        77% {transform: rotateX(0deg)}
      }

      .ad-front {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        backface-visibility: hidden;
        overflow: hidden;
        background: #ffc728;
        color: #000;
      }

      .ad-back {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        backface-visibility: hidden;
        overflow: hidden;
        background: #fafafa;
        color: #333;
        text-align: center;
        transform: rotateX(180deg);
      }



      /* mobile styles */
      @media all and (max-width: 768px){   

      }
  `

  render(){    
    return html`

    <aside>
      
      <div class="flip-container">
      <p>Ad</p>
        <div class="ad-holder">
          <div class="ad-front">
            <a href="/" class="home-button primary">Front Ad Image here</a>
          </div>

          <div class="ad-back">
            <a href="/" class="home-button primary">Back Ad Image here</a>
          </div>
      
        </div>
      </div>
    </aside>
  
    `
  }
})
