import App from '../App'
import {anchorRoute } from '../Router'
import {render, html} from 'lit/html.js'

class FourOFourView{
  init(){
    document.title = '404 File not found'    
    this.render()
  }

  render(){
    const template = html`    
    

      <div class="flex-center">
        
        <div class="lost">
          <h1>Wrong Page Address?</h1>
          <p>404 url error.</p>
          <br>
          <hr>
          <br>
          <a href="/" class="home-button primary" @click=${anchorRoute}>Home</a>
        </div>

      </div>
    `
    render(template, App.rootEl)
  }
}

export default new FourOFourView()