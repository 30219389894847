import App from '../App'
import {render, html} from 'lit/html.js'
import {gotoRoute, anchorRoute } from '../Router'


class DevPlayView {
  init(){    
    document.title = App.name + ' | Experience'   
    this.render() 
  }

  render(){
    const template = html`

        
      <body id="contentpanelid" class="content-panel">  
        <main-app-header pagename="${document.title}"></main-app-header>
        <div class="dde-head dde-head">

        <div class="dde-img-holder flex-center">
            <div class="dde-img">
              <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" 
                  viewBox="0 0 300 300" enable-background="new 0 0 300 300" xml:space="preserve">
                  <g>
                    <path fill="#DFDFDF" d="M160.354,207.457c-0.777,4.688-4.861,8.283-9.767,8.283c-4.908,0-8.995-3.595-9.766-8.283h-24.993v54.159
                      h69.519v-54.159H160.354L160.354,207.457L160.354,207.457z"/>
                    <rect x="117.036" y="261.414" fill="#BCB9CB" width="6.872" height="1.21"/>
                    <rect x="177.866" y="261.611" fill="#BCB9CB" width="6.868" height="1.215"/>
                    <rect x="116.032" y="257.319" fill="#2F2E41" width="69.512" height="1.884"/>
                    <path fill="#2F2E41" d="M290.387,208.87H10.784c-2.138,0-3.877-1.739-3.877-3.876V45.426c0-2.14,1.739-3.879,3.877-3.879h279.603
                      c2.141,0,3.88,1.739,3.88,3.879v159.568c0,2.141-1.739,3.88-3.88,3.88l0,0V208.87z"/>
                    <rect x="13.978" y="48.417" fill="#FFFFFF" width="273.615" height="154.389"/>
                    <path fill="#DFDFDF" d="M150.946,98.316h-16.83c-5.288,0-9.59-4.3-9.59-9.588c0-5.29,4.303-9.593,9.59-9.593h16.83
                      c5.29,0,9.591,4.303,9.591,9.593C160.537,94.016,156.236,98.316,150.946,98.316z"/>
                    <circle fill="#FFFFFF" cx="134.455" cy="88.727" r="7.404"/>
                    <path fill="#DFDFDF" d="M150.946,164.419h-16.83c-5.288,0-9.59-4.3-9.59-9.592c0-5.288,4.303-9.588,9.59-9.588h16.83
                      c5.29,0,9.591,4.3,9.591,9.588C160.537,160.119,156.236,164.419,150.946,164.419z"/>
                    <path fill="#FFFFFF" d="M134.455,147.424c4.089,0,7.405,3.317,7.405,7.403c0,4.09-3.316,7.407-7.405,7.407
                      c-4.09,0-7.405-3.317-7.405-7.407C127.05,150.741,130.365,147.424,134.455,147.424z"/>
                    <path fill="#DFDFDF" d="M150.946,131.306h-16.83c-5.288,0-9.59-4.303-9.59-9.59c0-5.291,4.303-9.593,9.59-9.593h16.83
                      c5.29,0,9.591,4.302,9.591,9.593C160.537,127.003,156.236,131.306,150.946,131.306z"/>
                    <path fill="#2F2E43" d="M72.191,263.041c0,0.22,0.173,0.4,0.398,0.4H228.58c0.221,0,0.398-0.173,0.398-0.4
                      c0-0.223-0.176-0.396-0.398-0.396H72.589C72.371,262.645,72.191,262.818,72.191,263.041z"/>
                    <polygon fill="#F2A7AA" points="181.698,128.397 174.277,130.836 174.277,120.149 181.011,120.149 		"/>
                    <circle fill="#F2A7AA" cx="175.197" cy="115.712" r="7.396"/>
                    <path fill="#36344E" d="M177.227,114.979c-1.239-0.04-2.052-1.287-2.532-2.432c-0.484-1.142-0.979-2.452-2.126-2.925
                      c-0.938-0.386-2.593,2.221-3.338,1.524c-0.772-0.723-0.021-4.438,0.802-5.11c0.819-0.669,1.941-0.797,2.997-0.845
                      c2.575-0.119,5.17,0.089,7.698,0.618c1.563,0.325,3.17,0.814,4.296,1.946c1.43,1.431,1.793,3.596,1.898,5.616
                      c0.105,2.069-0.012,4.232-1.02,6.042s-3.111,3.146-5.128,2.681c-0.201-1.095,0-2.22,0.083-3.333c0.078-1.114,0-2.313-0.684-3.202
                      c-0.682-0.89-2.129-1.237-2.923-0.45"/>
                    <path fill="#36344E" d="M186.327,117.544c0.737-0.545,1.623-0.996,2.537-0.886c0.982,0.118,1.813,0.929,2.065,1.888
                      c0.255,0.958-0.029,2.016-0.639,2.801c-0.607,0.783-1.513,1.301-2.473,1.56c-0.552,0.146-1.159,0.21-1.686-0.015
                      c-0.78-0.333-1.195-1.328-0.895-2.12"/>
                    <rect x="172.298" y="247.289" fill="#F2A7AA" width="6.95" height="9.865"/>
                    <path fill="#36344E" d="M165.522,263.128c-0.733,0-1.381-0.021-1.871-0.066c-1.849-0.169-3.608-1.533-4.494-2.329
                      c-0.399-0.36-0.527-0.931-0.318-1.417l0,0c0.146-0.355,0.444-0.62,0.813-0.724l4.882-1.392l7.905-5.333l0.086,0.156
                      c0.036,0.054,0.811,1.454,1.068,2.4c0.102,0.359,0.074,0.656-0.079,0.888c-0.104,0.161-0.249,0.257-0.366,0.306
                      c0.145,0.148,0.59,0.454,1.97,0.678c2.016,0.317,2.441-1.769,2.458-1.858l0.012-0.071l0.065-0.037
                      c0.958-0.619,1.551-0.899,1.751-0.838c0.126,0.041,0.341,0.099,0.911,5.791c0.058,0.183,0.46,1.492,0.186,2.739
                      c-0.3,1.364-6.25,0.893-7.436,0.784C173.029,262.805,168.573,263.128,165.522,263.128L165.522,263.128L165.522,263.128z"/>
                    
                      <rect x="195.23" y="240.632" transform="matrix(-0.8484 0.5293 -0.5293 -0.8484 497.2711 348.7368)" fill="#F2A7AA" width="6.952" height="9.864"/>
                    <path fill="#36344E" d="M191.211,261.769c-0.814,0-1.564-0.096-2.097-0.189c-0.527-0.092-0.94-0.509-1.023-1.038l0,0
                      c-0.062-0.379,0.055-0.76,0.308-1.044l3.404-3.764l3.881-8.704l0.158,0.087c0.062,0.028,1.46,0.805,2.179,1.47
                      c0.276,0.257,0.411,0.521,0.406,0.802c0,0.189-0.074,0.343-0.148,0.454c0.202,0.054,0.741,0.071,2.028-0.475
                      c1.88-0.793,1.134-2.792,1.101-2.871l-0.026-0.066l0.031-0.066c0.485-1.036,0.836-1.582,1.041-1.639
                      c0.13-0.038,0.343-0.096,3.84,4.428c0.145,0.115,1.177,1.02,1.608,2.227c0.468,1.313-4.831,4.061-5.896,4.603
                      c-0.03,0.024-5.576,4.057-7.85,5.196c-0.903,0.459-1.974,0.599-2.945,0.599l0,0V261.769z"/>
                    <path fill="#36344E" d="M184.367,164.804h-19.466l-1.767,18.106l7.73,66.907h9.937l-3.974-38.642l16.117,34.887l8.834-6.181
                      l-12.59-32.573c0,0,4.497-28.373,0.967-35.437C186.625,164.804,184.367,164.804,184.367,164.804L184.367,164.804L184.367,164.804z
                      "/>
                    <polygon fill="#6C63FF" points="194.051,165.907 163.135,165.907 172.412,125.933 185.879,125.933 		"/>
                    <path id="uuid-4f4c8d73-174a-4aab-b932-a11e56a3b943-90-45-53-45-92-154-49-49-53_1_" fill="#F2A7AA" d="M198.817,186.843
                      c0.496,2.433-0.411,4.655-2.017,4.961c-1.606,0.307-3.306-1.412-3.802-3.846c-0.211-0.967-0.18-1.97,0.097-2.933l-1.954-10.328
                      l5.053-0.797l1.388,10.266C198.208,184.951,198.633,185.868,198.817,186.843L198.817,186.843L198.817,186.843z"/>
                    <path fill="#6C63FF" d="M178.782,125.933c0,0,6.752-0.283,7.104,0c1.838,1.507,12.647,55.795,12.647,55.795h-6.844l-12.903-55.795
                      l0,0H178.782z"/>
                  </g>
                  <g id="toggle-on">
                    <circle id="button" fill="#6C63FF" cx="150.424" cy="121.714" r="7.404">
                        <animate  attributeName="fill" dur="2s" repeatCount="indefinite"
                            values="#6C63FF;
                                    #FFFFFF;
                                    #6C63FF"
                            keyTimes="0; 0.5; 1"/>
                        <animate  attributeName="cx" dur="2s" repeatCount="indefinite"
                            values="150.424;
                                    134.455;
                                    150.424"
                            keyTimes="0; 0.5; 1"/>
                    </circle>
                    <path id="hand" fill="#F2A7AA" d="M155.46,125.15c-0.496-2.434,0.411-4.654,2.016-4.962c1.607-0.305,3.305,1.417,3.803,3.846
                        c0.209,0.971,0.179,1.975-0.097,2.933l1.953,10.327l-5.053,0.797l-1.389-10.267C156.066,127.042,155.644,126.127,155.46,125.15
                        L155.46,125.15L155.46,125.15z">
                        <animate  attributeName="d" dur="2s" repeatCount="indefinite"
                              values="M155.46,125.15c-0.496-2.434,0.411-4.654,2.016-4.962c1.607-0.305,3.305,1.417,3.803,3.846
                                  c0.209,0.971,0.179,1.975-0.097,2.933l1.953,10.327l-5.053,0.797l-1.389-10.267C156.066,127.042,155.644,126.127,155.46,125.15
                                  L155.46,125.15L155.46,125.15z;
                                  M133.714,134.334c-1.943-1.545-2.676-3.83-1.645-5.096c1.036-1.268,3.442-1.039,5.386,0.501
                                  c0.784,0.61,1.405,1.398,1.811,2.309l8.134,6.656l-3.358,3.858l-7.662-6.972C135.396,135.394,134.484,134.964,133.714,134.334
                                  L133.714,134.334L133.714,134.334z;
                                  M155.46,125.15c-0.496-2.434,0.411-4.654,2.016-4.962c1.607-0.305,3.305,1.417,3.803,3.846
                                  c0.209,0.971,0.179,1.975-0.097,2.933l1.953,10.327l-5.053,0.797l-1.389-10.267C156.066,127.042,155.644,126.127,155.46,125.15
                                  L155.46,125.15L155.46,125.15z"
                              keyTimes="0; 0.5; 1"/>
                    </path>
                    <path id="arm_1_" fill="#6C63FF" d="M173.672,125.933l-8.009,16.017l-3.019-12.004l-6.639,0.707c0,0,1.57,23.453,8.434,22.654
                        c6.865-0.789,22.068-21.914,20.311-27.369L173.672,125.933z">
                        <animate  attributeName="d" dur="2s" repeatCount="indefinite"
                            values="M173.672,125.933l-8.009,16.017l-3.019-12.004l-6.639,0.707c0,0,1.57,23.453,8.434,22.654
                                c6.865-0.789,22.068-21.914,20.311-27.369L173.672,125.933z;
                                M173.125,126.5l-17.25,16.5l-13.573-9.611l-4.631,4.809c0,0,16.079,15.302,21.022,11.932
                                c5.709-3.892,31.158-20.831,26.307-23.88L173.125,126.5z;
                                M173.672,125.933l-8.009,16.017l-3.019-12.004l-6.639,0.707c0,0,1.57,23.453,8.434,22.654
                                c6.865-0.789,22.068-21.914,20.311-27.369L173.672,125.933z"
                            keyTimes="0; 0.5; 1"/>
                    </path>
                  </g>  
              </svg>
            </div>
          </div>

          <div class="dde-tx-holder flex-center">
            <div class="dde-text">
                <h1>experience</h1>
                <p>Test</p>
                <p>Refine</p>
            </div>
          </div>


        </div> 
        <!-- end dde-head -->


        <main class="secondary-content">
          <!-- <wa-ads-desktop></wa-ads-desktop> -->
        <section>
          <h2 class="dde-heading">User Experience</h2>
          <p><strong>User Experience (UX) </strong>
          refers to the overall experience a person has when using a website. 
          It encompasses various aspects that influence how users perceive and interact with the website. 
          It’s a blend of usability, aesthetics, and functionality. A great user experience is an enjoyable interaction with your website. 
          </p>
          <p>Some key UX points include:</p>
          <ul>
              <li><strong>Fast loading response times: </strong>
              The website should respond quickly on all devices.</li>
              <li><strong>User Interface (UI): </strong>
              The website should be aesthetically pleasing.</li>
              <li><strong>Intuitive navigation: </strong>
              Users should find what they’re looking for easily.</li>
              <li><strong>Responsive design: </strong>
              The site should look good and function well on any device.</li>
              <li><strong>Clear content: </strong>
              Information should be easy to read and understand.</li>
              <li><strong>Accessibility: </strong>
              The site should be usable by everyone, including those with disabilities.</li>
              <li><strong>Holistic Journey: </strong>
              The user flow and interactions should be minimal, smooth and enjoyable.</li>
          </ul>
          <p>
            A great UX design creates meaningful, relevant, and positive experiences for users throughout their interaction with the website. An enjoyable user experience, increases user interactivity and promotes higher conversion rates.
          </p>
        </section>

        <section>
          <img class="orange-img" src="/images/draw/experience.svg" alt="experiencing a website in the park">

          <h2 class="dde-heading">Testing</h2>
          <p><strong>Website testing </strong>
          involves a comprehensive evaluation of a web-based application or website to ensure its functionality, quality, and performance before making it accessible to the public. A few aspects of website testing include:
          </p>
          <strong>Functionality Testing: </strong>
          <ul>
              <li>Verify that all features and functionalities work as intended.</li>
              <li>Test forms, buttons, links, navigation, and interactive elements.</li>
              <li>Identify and fix any bugs or issues.</li>
          </ul>
          <strong>Usability Testing:</strong>
          <ul>
              <li>Assess the user-friendliness of the website.</li>
              <li>Evaluate layout, readability, and ease of navigation.</li>
              <li>Ensure a positive user experience.</li>
          </ul>
          <strong>Security Testing:</strong>
          <ul>
              <li>Check for vulnerabilities and potential security risks.</li>
              <li>Validate input validation, authentication, and authorization mechanisms.</li>
              <li>Protect against malicious input data.</li>
          </ul>
          <strong>Compatibility Testing:</strong>
          <ul>
              <li>Test across different browsers (e.g., Chrome, Firefox, Safari, Edge).</li>
              <li>Ensure consistent behavior on various devices (desktops, tablets, mobiles).</li>
              <li>Validate compatibility with different operating systems.</li>
          </ul>
          <strong>Accessibility Testing:</strong>
          <ul>
              <li>Ensure the website is usable by people with disabilities.</li>
              <li>Check compliance with accessibility standards.</li>
              <li>Test screen readers, keyboard navigation, and color contrast.</li>
          </ul>
          <strong>Content Testing:</strong>
          <ul>
              <li>Review and validate all content (text, images, videos).</li>
              <li>Check for broken links and missing assets.</li>
              <li>Confirm accurate and up-to-date information.</li>
          </ul>
          <strong>Cross-Device Testing:</strong>
          <ul>
              <li>Test on different devices (phones, tablets, desktops).</li>
              <li>Verify responsive design and consistent behavior.</li>
          </ul>
          <strong>Regression Testing:</strong>
          <ul>
              <li>After making changes or updates, retest existing features.</li>
              <li>Ensure new changes haven’t introduced new issues.</li>
          </ul>
          <p>Thorough website testing ensures a high-quality user experience providing quality assurance when your site goes live.</p>
        </section>

          <section>
            <hr>
            <br>
            <p>Reach out to discuss the UX specifications for your website.</p>
            <a class="btn contact-btn" href="/contact" @click="${anchorRoute}">Contact us</a>
          </section>
          <!-- <wa-ads-mobile></wa-ads-mobile> -->
        </main>
        
        <main-app-footer></main-app-footer>

      </body> 
     


    `

    render(template, App.rootEl)
  }
}

export default new DevPlayView()