import App from '../App'
import {render, html} from 'lit/html.js'
import MailAPI from '../api/MailAPI'
import Validator from '../functions/Validator'


class ContactView {
  init(){    
    // console.log('Contact View.init')
    document.title = App.name + ' | Contact Us'    
    this.render() 
  }


  async contactSubmitHandler(e){ 
    e.preventDefault()
    // validate the data
    const inputs = document.querySelectorAll('#fname, #userMessage')
    const emailInput = document.getElementById('userEmail')
    let readyToSubmit = true
    //check email is valid
    const emailStatus = Validator.validateEmail(emailInput)
    if(emailStatus == false){ readyToSubmit = false }
    //check other inputs are valid
    inputs.forEach(input => {
      if(input.value == "") {
        readyToSubmit = false
        if (input.classList.contains('input-warning')){ return } 
        else { Validator.addWarning(input, '*Required') }
      } else {
        const safeStatus = Validator.safeCheck(input)
        if(safeStatus === false){ readyToSubmit = false }
      }
    }) 
    // convert to html code to add to email??
    // --- SKIP FOR NOW --- = TEST LIVE

    // SET object to send: App.clientCode senderName senderEmail message (via form names)

    const mainTab = document.getElementById('main-tab')
    const statusTab = document.getElementById('status-tab')
      if (readyToSubmit === true) {
        mainTab.classList.add('hidden')
        statusTab.innerHTML = '<c-waiting></c-waiting>'
        const form = document.querySelector('form')
        const formData = new FormData(form)
        const formObject = {};
        formData.forEach((value, key) => formObject[key] = value);
        formObject.clientCode = App.clientCode;
        const formJson = JSON.stringify(formObject);
          try{
            const message = await MailAPI.sendContact(formJson)
            statusTab.innerHTML = '<c-success></c-success>'
            console.log(message)
          }catch(err){
            console.log(err)
            statusTab.innerHTML = '<c-error></c-error>'
          }
      } else { return }
    
  } // end of contactSubmitHandler

 




  async originalcontactSubmitHandler(e){
    e.preventDefault()
    const inputs = document.querySelectorAll('#fname, #userMessage')
    const emailInput = document.getElementById('userEmail')
    let readyToSubmit = true
    const emailStatus = Validator.validateEmail(emailInput)
    if(emailStatus == false){ readyToSubmit = false }
    inputs.forEach(input => {
      if(input.value == "") {
        readyToSubmit = false
        if (input.classList.contains('input-warning')){ return } 
        else { Validator.addWarning(input, '*Required') }
      } else {
        const safeStatus = Validator.safeCheck(input)
        if(safeStatus == false){ readyToSubmit = false }
      }
    }) 
    const mainTab = document.getElementById('main-tab')
    const statusTab = document.getElementById('status-tab')
    if (readyToSubmit == true) {
      mainTab.classList.add('hidden')
      statusTab.innerHTML = '<c-waiting></c-waiting>'
      const form = document.querySelector('form')
      const formData = new FormData(form)
      const formObject = {};
      formData.forEach((value, key) => formObject[key] = value);
      const formJson = JSON.stringify(formObject);
        try{
          const message = await MailAPI.sendContact(formJson)
          statusTab.innerHTML = '<c-success></c-success>'
        }catch(err){
          console.log(err)
          statusTab.innerHTML = '<c-error></c-error>'
        }
    } else { return }
  }

  render(){
    const template = html`   

    
      <body>  
        <main-app-header pagename="${document.title}"></main-app-header>
        <main  class="page-head flex-center contact-main">
          
          <div class="contact-window"> 
            
            <div id="main-tab">
              <div id="form-head">
                Get in touch with us to discuss your custom website requirements.
              </div>
              <hr>

              <form class="page-form" method="post">

                <div class="input-holder">
                  <input type="text"  id="fname" name="senderName" placeholder="Your name ..." @keyup=${Validator.clearWarning} required>
                  <div id="fn-req" class="required"></div>
                </div>

                <div class="input-holder">
                  <input type="email" id="userEmail" name="senderEmail" placeholder="Email ..."  @keyup=${Validator.clearWarning} required>
                  <div id="em-req" class="required"></div>
                </div>

                <div class="input-holder">
                  <textarea id="userMessage" name="message" placeholder="Message ..." rows="5"  @keyup=${Validator.clearWarning} required></textarea>
                  <div id="ms-req" class="required"></div>
                </div>
              
                <button class="form-btn" type="submit" @click="${this.contactSubmitHandler}">Send Message</button>
              </form>
            </div>
            <div id="status-tab"> </div>
          </div>
        
        </main>
        <main-app-footer></main-app-footer>
      </body> 

      
    `

    render(template, App.rootEl)
  }
}

export default new ContactView()