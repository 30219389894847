import App from '../App'
import {render, html} from 'lit/html.js'
import {gotoRoute, anchorRoute } from '../Router'


class DevPlayView {
  init(){    
    document.title = App.name + ' | Develop'  
    this.render() 
  }

  render(){
    const template = html`

    
      <body id="contentpanelid" class="content-panel">  
        <main-app-header pagename="${document.title}"></main-app-header>
        <div class="dde-head">


          <div class="dde-img-holder flex-center">
            <div class="dde-img">


                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                  viewBox="0 0 300 300" enable-background="new 0 0 300 300" xml:space="preserve">
                  <g id="Layer_1_1_">
                    <g>
                      <path id="uuid-fb20b2b5-bc5b-4117-a1ee-c5d1a8aa62bd-46_1_" fill="#A0616A" d="M82.567,43.432c1.186-3,3.746-4.776,5.715-3.978
                        c1.966,0.807,2.603,3.893,1.414,6.892c-0.458,1.204-1.202,2.273-2.186,3.117l-5.191,12.634l-6.076-2.71l5.769-12.169
                        c-0.131-1.299,0.059-2.598,0.55-3.789l0,0L82.567,43.432z"/>
                      <path fill="#E6E6E6" d="M60.795,124.098c0,0-8.712-4.217-8.107-7.249c0.602-3.036,26.563-67.64,26.563-67.64l12.018,7.893
                        l-30.473,67L60.795,124.098L60.795,124.098z"/>
                      <path fill="#D7D7D8" d="M191.477,280.906h-83.337c-12.122,0-21.984-9.86-21.984-21.979V41.298
                        c0-12.124,9.862-21.989,21.984-21.989h83.337c12.121,0,21.981,9.865,21.981,21.989v217.628
                        C213.458,271.046,203.598,280.906,191.477,280.906z"/>
                      <path fill="#FFFFFF" d="M191.535,274.957h-83.461c-8.969,0-16.274-7.303-16.274-16.273V41.109c0-8.971,7.305-16.274,16.274-16.274
                        h83.461c8.974,0,16.273,7.303,16.273,16.274v217.575C207.809,267.654,200.509,274.957,191.535,274.957z"/>
                      <path fill="#D7D7D8" d="M161.609,39.318h-23.603c-3.114,0-5.649-2.535-5.649-5.647c0-3.117,2.535-5.647,5.649-5.647h23.603
                        c3.115,0,5.646,2.53,5.646,5.647C167.257,36.783,164.725,39.318,161.609,39.318z"/>
                      <path fill="#6C63FF" d="M110.363,106.394h78.892c3.57,0,6.463,2.892,6.463,6.459l0,0c0,3.573-2.893,6.464-6.463,6.464h-78.892
                        c-3.57,0-6.466-2.891-6.466-6.464l0,0C103.897,109.286,106.793,106.394,110.363,106.394z"/>
                      <path fill="#6C63FF" d="M106.933,95.65h26.103c0.623,0,1.127,0.505,1.127,1.128l0,0c0,0.623-0.505,1.127-1.127,1.127h-26.103
                        c-0.623,0-1.127-0.505-1.127-1.127l0,0C105.806,96.155,106.311,95.65,106.933,95.65z"/>
                      <path fill="#D7D7D8" d="M110.363,150.146h78.892c3.57,0,6.463,2.896,6.463,6.463l0,0c0,3.566-2.893,6.464-6.463,6.464h-78.892
                        c-3.57,0-6.466-2.896-6.466-6.464l0,0C103.897,153.042,106.793,150.146,110.363,150.146z"/>
                      <path fill="#D7D7D8" d="M106.933,139.402h26.103c0.623,0,1.127,0.5,1.127,1.127l0,0c0,0.618-0.505,1.123-1.127,1.123h-26.103
                        c-0.623,0-1.127-0.505-1.127-1.123l0,0C105.806,139.903,106.311,139.402,106.933,139.402z"/>
                      <path fill="#D7D7D8" d="M110.363,193.898h78.892c3.57,0,6.463,2.891,6.463,6.459l0,0c0,3.565-2.893,6.463-6.463,6.463h-78.892
                        c-3.57,0-6.466-2.896-6.466-6.463l0,0C103.897,196.789,106.793,193.898,110.363,193.898z"/>
                      <path fill="#D7D7D8" d="M106.933,183.154h26.103c0.623,0,1.127,0.505,1.127,1.128l0,0c0,0.622-0.505,1.123-1.127,1.123h-26.103
                        c-0.623,0-1.127-0.501-1.127-1.123l0,0C105.806,183.659,106.311,183.154,106.933,183.154z"/>
                      <path fill="#2F2E41" d="M235.573,72.565c0,0-8.897,1.006-9.47,14.781c-0.48,11.475-1.455,17.875,3.215,20.271
                        c1.67,0.857,3.632,0.934,5.414,0.347l19.065-6.27c0,0,0.287-20.667-6.313-25.688C240.882,70.987,235.573,72.565,235.573,72.565z"
                        />
                      <polygon fill="#FFB6B6" points="246.087,104.233 236.439,107.4 236.439,93.517 245.189,93.517 		"/>
                      <path id="uuid-e639489a-d12b-4c23-97cb-2af79ea40b7d-47_1_" fill="#FFB6B6" d="M216.593,183.077
                        c-0.524,3.181-2.646,5.463-4.739,5.098c-2.094-0.366-3.367-3.248-2.845-6.428c0.189-1.276,0.692-2.485,1.474-3.519l2.389-13.451
                        l6.509,1.367l-3.051,13.115C216.73,180.502,216.82,181.811,216.593,183.077L216.593,183.077L216.593,183.077z"/>
                      <path id="uuid-56df27a3-bbb6-4bd2-b7f5-888148e3fe66-48_1_" fill="#FFB6B6" d="M269.191,181.535
                        c0.644,3.156-0.537,6.044-2.623,6.445c-2.088,0.399-4.305-1.836-4.94-4.998c-0.274-1.265-0.228-2.566,0.124-3.809l-2.544-13.423
                        l6.571-1.042l1.808,13.348C268.402,179.072,268.949,180.268,269.191,181.535L269.191,181.535L269.191,181.535z"/>
                      <rect x="233.424" y="259.711" fill="#FFB6B6" width="9.035" height="12.823"/>
                      <path fill="#2F2E41" d="M224.624,280.297c-0.952,0-1.798-0.021-2.435-0.085c-2.398-0.217-4.689-1.994-5.847-3.026
                        c-0.517-0.465-0.681-1.209-0.413-1.85l0,0c0.192-0.456,0.582-0.803,1.061-0.934l6.339-1.813l10.272-6.933l0.116,0.207
                        c0.04,0.08,1.056,1.895,1.394,3.121c0.125,0.465,0.09,0.855-0.1,1.154c-0.138,0.207-0.326,0.328-0.479,0.4
                        c0.188,0.188,0.771,0.591,2.563,0.875c2.621,0.41,3.172-2.301,3.193-2.413l0.02-0.09l0.075-0.056
                        c1.247-0.803,2.015-1.168,2.28-1.092c0.165,0.045,0.438,0.135,1.188,7.527c0.071,0.23,0.592,1.932,0.238,3.559
                        c-0.384,1.773-8.119,1.16-9.666,1.024c-0.043,0.004-5.837,0.415-9.802,0.415v0.008H224.624z"/>
                      
                        <rect x="263.225" y="251.059" transform="matrix(-0.8487 0.5289 -0.5289 -0.8487 631.149 334.377)" fill="#FFB6B6" width="9.038" height="12.821"/>
                      <path fill="#2F2E41" d="M258.009,278.538c-1.063,0-2.038-0.126-2.735-0.248c-0.682-0.122-1.215-0.663-1.324-1.349l0,0
                        c-0.079-0.491,0.063-0.988,0.4-1.354l4.422-4.897l5.047-11.309l0.209,0.107c0.078,0.046,1.896,1.052,2.832,1.912
                        c0.355,0.33,0.531,0.678,0.524,1.039c-0.002,0.248-0.1,0.444-0.194,0.59c0.262,0.068,0.963,0.096,2.639-0.612
                        c2.443-1.028,1.479-3.632,1.432-3.739l-0.031-0.086l0.039-0.081c0.635-1.345,1.088-2.057,1.354-2.134
                        c0.169-0.045,0.443-0.117,4.986,5.766c0.188,0.152,1.529,1.32,2.091,2.887c0.606,1.705-6.274,5.285-7.659,5.984
                        c-0.043,0.031-7.246,5.271-10.205,6.758c-1.175,0.59-2.564,0.771-3.829,0.771L258.009,278.538z"/>
                      <path fill="#2F2E41" d="M249.109,152.496h-25.297l-2.297,23.536l10.045,86.963h12.918l-5.166-50.226l20.951,45.344l11.478-8.036
                        l-16.354-42.332c0,0,5.84-36.878,1.246-46.065C252.043,152.496,249.109,152.496,249.109,152.496L249.109,152.496z"/>
                      <polygon fill="#6C63FF" points="261.693,153.932 217.786,153.932 233.573,101.983 251.08,101.983 		"/>
                      <path fill="#6C63FF" d="M236.336,101.992c0,0-10.906-0.293-12.053,2.585c-1.15,2.869-14.637,71.18-14.637,71.18h8.896
                        L236.336,101.992L236.336,101.992z"/>
                      <path fill="#6C63FF" d="M243.43,102.051c0,0,9.604-1.218,10.754,1.655c1.147,2.869,14.641,71.176,14.641,71.176h-8.899
                        L243.43,102.051z"/>
                      <path fill="#FFB6B6" d="M237.635,78.131c5.309,0,9.611,4.303,9.611,9.616c0,5.309-4.303,9.612-9.611,9.612
                        c-5.312,0-9.613-4.303-9.613-9.612C228.021,82.434,232.323,78.131,237.635,78.131z"/>
                      <path fill="#2F2E41" d="M235,76.151c0,0-5.288,21.483,0.594,31.528l-8.285-1.759c0,0-3.213-23.518,3.244-27.793l4.447-1.98V76.151
                        z"/>
                      <path fill="#2E2E41" d="M296.279,279.783c0,0.355-0.291,0.645-0.646,0.645H4.075c-0.361,0-0.649-0.287-0.649-0.645
                        c0-0.361,0.289-0.649,0.649-0.649h291.558C295.989,279.134,296.279,279.422,296.279,279.783z"/>
                      <polygon fill="#A0616A" points="53.098,94.716 64.148,98.347 64.148,82.439 54.122,82.439 		"/>
                      <path fill="#A0616A" d="M62.782,64.812c6.083,0,11.013,4.935,11.013,11.015c0,6.085-4.93,11.015-11.013,11.015
                        c-6.082,0-11.012-4.93-11.012-11.015C51.77,69.746,56.7,64.812,62.782,64.812z"/>
                      <path fill="#2F2E41" d="M59.977,76.688l1.518,1.845l2.747-4.808c0,0,3.502,0.18,3.502-2.422c0-2.602,3.216-2.67,3.216-2.67
                        s4.553-7.952-4.878-5.859c0,0-6.541-4.479-9.79-0.649c0,0-9.975,5.02-7.122,13.762l4.743,9.016l1.076-2.039
                        c0,0-0.652-8.565,4.989-6.179L59.977,76.688L59.977,76.688z"/>
                      <rect x="49.778" y="259.852" fill="#A0616A" width="9.037" height="12.818"/>
                      <path fill="#2F2E41" d="M57.818,280.014c-1.543,0.141-9.278,0.754-9.667-1.02c-0.354-1.629,0.167-3.329,0.244-3.563
                        c0.74-7.395,1.015-7.477,1.187-7.523c0.262-0.077,1.031,0.287,2.278,1.091l0.077,0.05l0.018,0.096
                        c0.02,0.117,0.573,2.828,3.193,2.408c1.793-0.283,2.377-0.682,2.562-0.875c-0.151-0.068-0.341-0.188-0.474-0.396
                        c-0.194-0.304-0.23-0.69-0.099-1.154c0.336-1.229,1.344-3.05,1.389-3.121l0.117-0.208l10.268,6.93l6.347,1.813
                        c0.478,0.141,0.864,0.482,1.055,0.938l0,0c0.269,0.638,0.104,1.381-0.413,1.847c-1.152,1.036-3.441,2.811-5.841,3.03
                        c-0.641,0.06-1.488,0.08-2.436,0.08c-3.965,0-9.758-0.41-9.801-0.42h-0.004V280.014z"/>
                      <path fill="#2F2E41" d="M70.357,149.312l-23.703-1.208c0,0-3.636,13.77,0.958,28.126l0.55,86.432H61.56l12.625-89.159
                        L70.357,149.312z"/>
                      <path fill="#E6E6E6" d="M66.356,91.658l-13.008-6.901c0,0-14.023,20.487-12.083,31.587c1.942,11.096,5.385,31.763,5.385,31.763
                        l28.701,1.213l-5.009-40.689L66.356,91.658L66.356,91.658z"/>
                      <rect x="56.208" y="259.852" fill="#A0616A" width="9.039" height="12.818"/>
                      <path fill="#2F2E41" d="M64.248,280.014c-1.545,0.141-9.276,0.754-9.666-1.02c-0.354-1.629,0.169-3.329,0.241-3.563
                        c0.744-7.395,1.02-7.477,1.186-7.523c0.266-0.077,1.033,0.287,2.28,1.091l0.077,0.05l0.018,0.096
                        c0.02,0.117,0.575,2.828,3.193,2.408c1.795-0.283,2.377-0.682,2.564-0.875c-0.153-0.068-0.343-0.188-0.479-0.396
                        c-0.191-0.304-0.225-0.69-0.097-1.154c0.336-1.229,1.346-3.05,1.387-3.121l0.12-0.208l10.268,6.93l6.347,1.813
                        c0.478,0.141,0.861,0.482,1.055,0.938l0,0c0.269,0.638,0.104,1.381-0.415,1.847c-1.15,1.036-3.437,2.811-5.841,3.03
                        c-0.638,0.06-1.484,0.08-2.434,0.08c-3.967,0-9.758-0.41-9.799-0.42h-0.005V280.014z"/>
                      <path fill="#2F2E41" d="M75.351,149.312l-28.701-1.208c0,0-3.635,13.77,0.958,28.126l5.55,86.432h13.394l12.627-89.159
                        L75.351,149.312z"/>
                    </g>
                  </g>
   

                  <g id="arm-in">
                    <path id="hand" fill="#A0616A" d="M86.379,171.883
                      c1.058,3.049,0.275,6.066-1.741,6.738c-2.014,0.677-4.506-1.245-5.562-4.294c-0.442-1.214-0.568-2.513-0.379-3.789l-4.303-12.968
                      l6.369-1.903l3.568,12.985c0.947,0.897,1.651,2.013,2.052,3.229l0,0L86.379,171.883L86.379,171.883z">
                        <animate  attributeName="d" dur="2s" repeatCount="indefinite"
                            values="M86.379,171.883
                      c1.058,3.049,0.275,6.066-1.741,6.738c-2.014,0.677-4.506-1.245-5.562-4.294c-0.442-1.214-0.568-2.513-0.379-3.789l-4.303-12.968
                      l6.369-1.903l3.568,12.985c0.947,0.897,1.651,2.013,2.052,3.229l0,0L86.379,171.883L86.379,171.883z;
                      M124.44,137.189c2.77,1.656,4.11,4.471,2.998,6.281
                      c-1.108,1.813-4.252,1.943-7.021,0.286c-1.119-0.646-2.05-1.56-2.726-2.659l-11.631-7.168l3.655-5.552l11.08,7.654
                      c1.302,0.079,2.559,0.481,3.647,1.155l0,0L124.44,137.189L124.44,137.189z;
                                    M86.379,171.883
                      c1.058,3.049,0.275,6.066-1.741,6.738c-2.014,0.677-4.506-1.245-5.562-4.294c-0.442-1.214-0.568-2.513-0.379-3.789l-4.303-12.968
                      l6.369-1.903l3.568,12.985c0.947,0.897,1.651,2.013,2.052,3.229l0,0L86.379,171.883L86.379,171.883z"
                            keyTimes="0; 0.5; 1" />
                    </path>
                    <path fill="#E6E6E6" d="M50.268,96.534l10.879,0.208l23.978,68.6l-14.258,1.854L50.268,96.534z">
                        <animate  attributeName="d" dur="2s" repeatCount="indefinite"
                            values="M50.268,96.534l10.879,0.208l23.978,68.6l-14.258,1.854L50.268,96.534z;
                                    M48.75,102.333L57,95.417l62.5,37l-9.667,10.75L48.75,102.333z;
                                    M50.268,96.534l10.879,0.208l23.978,68.6l-14.258,1.854L50.268,96.534z"
                            keyTimes="0; 0.5; 1" />
                    </path>
                  </g>
                </svg>

            </div>
          </div>


          <div class="dde-tx-holder flex-center">
            <div class="dde-text">
                <h1>develop</h1>
                <p>Program</p>
                <p>Deploy</p>
            </div>
          </div>

        </div>

        <main class="secondary-content">
          <!-- <wa-ads-desktop></wa-ads-desktop> -->
          <section>
              <p>
              Developing of your project software provides you with more flexibility and can be more cost effective in the long term.  
              </p>
              <p>
              Although some websites only require frontend development, different aspects of website development include:
              </p>

              <ul>
                <li>
                  <p><strong>Frontend: </strong>
                  Responsive styling, navigation, input validation, user functionality and animations.
                  </p>
                </li>
                <li>
                  <p><strong>Backend: </strong>
                  Data API’s, content management, databases, payment and automated email systems.
                  </p>
                </li>
            </ul>
              <img class="orange-img" src="/images/draw/develop.svg" alt="developing a website">
              <p>
              When we develop your custom web app you are provided with a unique solution catering to your needs and you retain full ownership of the developed codebase.  Furthermore, in the event your business decides to expand and take over development, you can purchase tutorial sessions to train your staff on your codebase.              </p>
          </section>
 

          <section>
            <hr>
            <br>
            <p>Reach out to discuss your development requirements.</p>
            <a class="btn contact-btn" href="/contact" @click="${anchorRoute}">Contact us</a>
          </section>
          <!-- <wa-ads-mobile></wa-ads-mobile> -->
        </main>
        
        <main-app-footer></main-app-footer>
      </body> 



    `

    render(template, App.rootEl)
  }
}

export default new DevPlayView()