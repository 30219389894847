import App from '../App'
import {render, html} from 'lit/html.js'
import {gotoRoute, anchorRoute } from '../Router'


class DevPlayView {
  init(){    
    document.title = App.name + ' | Design'
    this.render() 
  }

  render(){
    const template = html`

      <body id="contentpanelid" class="content-panel">  
        <main-app-header pagename="${document.title}"></main-app-header>

        <div class="dde-head clearfix">

          <div class="dde-img-holder flex-center">
            <div class="dde-img">
              <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" 
                 viewBox="0 0 300 300" enable-background="new 0 0 300 300" xml:space="preserve">
                <g id="Layer_1">
                  <g>
                    <path fill="#D7D7D8" d="M37.353,15.705h228.843c5.341,0,9.669,4.328,9.669,9.667v170.117c0,5.34-4.328,9.667-9.669,9.667H37.353
                      c-5.338,0-9.666-4.327-9.666-9.667V25.372C27.688,20.032,32.015,15.705,37.353,15.705z"/>
                    <path fill="#FFFFFF" d="M37.353,17.926c-4.104,0-7.443,3.343-7.443,7.446v170.113c0,4.104,3.339,7.444,7.443,7.444h228.843
                      c4.106,0,7.447-3.341,7.447-7.444V25.372c0-4.103-3.341-7.446-7.447-7.446H37.353z"/>
                    <rect x="150.885" y="16.838" fill="#D7D7D8" width="1.773" height="187.393"/>
                    <circle fill="#D7D7D8" cx="141.236" cy="44.837" r="2.509"/>
                    <circle fill="#D7D7D8" cx="162.313" cy="44.837" r="2.509"/>
                    <circle fill="#D7D7D8" cx="141.236" cy="77.635" r="2.51"/>
                    <circle fill="#D7D7D8" cx="162.313" cy="77.635" r="2.51"/>
                    <circle fill="#D7D7D8" cx="141.236" cy="110.428" r="2.51"/>
                    <circle fill="#D7D7D8" cx="162.313" cy="110.428" r="2.51"/>
                    <circle fill="#D7D7D8" cx="141.236" cy="143.225" r="2.509"/>
                    <circle fill="#D7D7D8" cx="162.313" cy="143.225" r="2.509"/>
                    <circle fill="#D7D7D8" cx="141.236" cy="176.018" r="2.51"/>
                    <circle fill="#D7D7D8" cx="162.313" cy="176.018" r="2.51"/>
                    <path fill="#D7D7D8" d="M141.468,43.68l-0.466-0.757c0.115-0.072,11.595-7.005,21.563,0.013l-0.511,0.729
                      C152.564,36.984,141.574,43.611,141.468,43.68L141.468,43.68z"/>
                    <path fill="#D7D7D8" d="M141.468,46.757l-0.466-0.754c0.115-0.072,11.595-7.007,21.563,0.013l-0.511,0.729
                      C152.564,40.062,141.574,46.689,141.468,46.757L141.468,46.757z"/>
                    <path fill="#D7D7D8" d="M141.468,76.618l-0.466-0.756c0.115-0.07,11.595-7.005,21.563,0.015l-0.511,0.727
                      C152.564,69.923,141.574,76.551,141.468,76.618L141.468,76.618z"/>
                    <path fill="#D7D7D8" d="M141.468,79.7l-0.466-0.754c0.115-0.072,11.595-7.005,21.563,0.013l-0.511,0.729
                      C152.564,73.006,141.574,79.634,141.468,79.7L141.468,79.7z"/>
                    <path fill="#D7D7D8" d="M141.468,109.557l-0.466-0.754c0.115-0.072,11.595-7.007,21.563,0.013l-0.511,0.729
                      C152.564,102.863,141.574,109.491,141.468,109.557L141.468,109.557z"/>
                    <path fill="#D7D7D8" d="M141.468,112.641l-0.466-0.757c0.115-0.07,11.59-7.004,21.563,0.015l-0.511,0.727
                      C152.564,105.946,141.574,112.573,141.468,112.641L141.468,112.641z"/>
                    <path fill="#D7D7D8" d="M141.468,142.496l-0.466-0.754c0.115-0.07,11.595-7.005,21.563,0.015l-0.511,0.727
                      C152.564,135.807,141.574,142.43,141.468,142.496L141.468,142.496z"/>
                    <path fill="#D7D7D8" d="M141.468,145.58l-0.466-0.754c0.115-0.072,11.59-7.005,21.563,0.013l-0.511,0.727
                      C152.564,138.886,141.574,145.514,141.468,145.58L141.468,145.58z"/>
                    <path fill="#D7D7D8" d="M141.468,175.435l-0.466-0.752c0.115-0.072,11.595-7.007,21.563,0.012l-0.511,0.724
                      c-9.489-6.682-20.479-0.054-20.585,0.013V175.435z"/>
                    <path fill="#D7D7D8" d="M141.468,178.519l-0.466-0.755c0.115-0.07,11.595-7.005,21.563,0.014l-0.511,0.725
                      c-9.489-6.682-20.479-0.054-20.585,0.013V178.519z"/>
                    <path fill="#2F2E43" d="M207.996,65.355c0,0-9.144,1.032-9.733,15.19c-0.494,11.797-1.497,18.371,3.305,20.834
                      c1.714,0.877,3.731,0.954,5.565,0.354l19.59-6.441c0,0,0.295-21.232-6.49-26.396C213.45,63.736,207.996,65.355,207.996,65.355
                      L207.996,65.355z"/>
                    <polygon fill="#F3A3A6" points="218.796,97.9 208.879,101.162 208.879,86.889 217.877,86.889 		"/>
                    <path id="uuid-89c22d20-9486-4802-b91b-ddf2a7c7ca0a-46-44-54" fill="#F3A3A6" d="M188.483,178.919
                      c-0.536,3.271-2.717,5.614-4.868,5.237c-2.154-0.377-3.459-3.336-2.923-6.606c0.194-1.311,0.717-2.55,1.511-3.612l2.457-13.822
                      l6.685,1.398l-3.13,13.478C188.627,176.267,188.721,177.613,188.483,178.919L188.483,178.919z"/>
                    <polygon fill="#F3A3A6" points="211.185,270.839 201.899,270.839 196.517,221.222 210.869,221.222 		"/>
                    <path fill="#2F2E43" d="M192.854,278.816c-0.976,0-1.845-0.021-2.501-0.085c-2.464-0.228-4.818-2.048-5.999-3.109
                      c-0.53-0.475-0.703-1.237-0.428-1.897l0,0c0.199-0.471,0.596-0.82,1.089-0.964l6.516-1.858l10.555-7.124l0.121,0.214
                      c0.044,0.079,1.079,1.945,1.427,3.207c0.131,0.48,0.098,0.882-0.102,1.19c-0.141,0.215-0.336,0.338-0.49,0.409
                      c0.19,0.199,0.791,0.604,2.634,0.897c2.697,0.426,3.257-2.363,3.284-2.478l0.017-0.094l0.08-0.056
                      c1.278-0.823,2.071-1.197,2.343-1.118c0.167,0.049,0.454,0.137,1.217,7.733c0.075,0.242,0.612,1.986,0.247,3.662
                      c-0.396,1.821-8.342,1.193-9.934,1.048c-0.042,0.004-5.989,0.428-10.067,0.428L192.854,278.816z"/>
                    <polygon fill="#F3A3A6" points="232.166,270.839 222.876,270.839 217.493,221.222 231.846,221.222 		"/>
                    <path fill="#2F2E43" d="M213.836,278.816c-0.977,0-1.848-0.021-2.501-0.085c-2.467-0.228-4.819-2.048-6.002-3.109
                      c-0.527-0.475-0.701-1.237-0.424-1.897l0,0c0.199-0.471,0.593-0.82,1.088-0.964l6.515-1.858l10.558-7.124l0.118,0.214
                      c0.045,0.079,1.081,1.945,1.427,3.207c0.133,0.48,0.097,0.882-0.103,1.19c-0.138,0.215-0.332,0.338-0.489,0.409
                      c0.191,0.199,0.793,0.604,2.635,0.897c2.695,0.426,3.258-2.363,3.282-2.478l0.018-0.094l0.081-0.056
                      c1.284-0.823,2.068-1.197,2.34-1.118c0.169,0.049,0.454,0.137,1.219,7.733c0.074,0.242,0.612,1.986,0.247,3.662
                      c-0.394,1.821-8.342,1.193-9.932,1.048c-0.046,0.004-5.994,0.428-10.071,0.428L213.836,278.816z"/>
                    <polygon fill="#6C63FF" points="234.836,148.969 189.715,148.969 205.935,95.585 223.925,95.585 		"/>
                    <path fill="#6C63FF" d="M208.771,95.594c0,0-11.206-0.292-12.383,2.658c-1.183,2.95-15.044,73.141-15.044,73.141h9.144
                      L208.771,95.6V95.594z"/>
                    <circle fill="#F3A3A6" cx="210.106" cy="80.958" r="9.88"/>
                    <path fill="#2F2E43" d="M207.404,69.043c0,0-5.437,22.079,0.607,32.398l-8.516-1.808c0,0-3.3-24.162,3.336-28.556L207.404,69.043z
                      "/>
                    <path fill="#2F2E43" d="M225.458,148.969h-29.611l-16.201,66.496c-0.297,2.809,1.483,5.428,4.209,6.162
                      c8.889,2.381,30.222,5.752,62.504-3.643c3.106-0.902,4.87-4.181,3.896-7.269L225.458,148.969L225.458,148.969z"/>
                    <path fill="#2F2E43" d="M298.505,278.816c0,0.294-0.233,0.529-0.525,0.529H2.025c-0.292,0-0.529-0.235-0.529-0.529
                      c0-0.293,0.236-0.528,0.529-0.528h295.955C298.271,278.288,298.505,278.523,298.505,278.816z"/>
                    <path fill="#6C63FF" d="M50.729,49.421c9.129-0.35,18.267-0.502,27.405-0.447c9.137,0.053,18.125,0.305,27.174,0.763
                      c5.153,0.258,10.296,0.581,15.441,0.973c0.857,0.067,0.853-1.27,0-1.333c-9.111-0.689-18.236-1.172-27.369-1.457
                      c-9.134-0.284-18.125-0.355-27.188-0.235c-5.158,0.07-10.314,0.204-15.468,0.405c-0.853,0.032-0.857,1.363,0,1.331l0,0H50.729z"/>
                    <path fill="#2F2E43" d="M50.729,64.499c9.129-0.35,18.267-0.502,27.405-0.449c9.137,0.055,18.125,0.307,27.174,0.765
                      c5.153,0.258,10.296,0.581,15.441,0.973c0.857,0.065,0.853-1.27,0-1.333c-9.111-0.688-18.236-1.172-27.369-1.456
                      c-9.134-0.286-18.125-0.356-27.188-0.237c-5.158,0.072-10.314,0.206-15.468,0.405c-0.853,0.032-0.857,1.365,0,1.333l0,0H50.729z"
                      />
                    <path fill="#2F2E43" d="M50.729,79.577c7.41-0.29,14.825-0.619,22.243-0.749c6.345-0.11,12.687-0.008,19.022,0.299
                      c4.517,0.219,9.031,0.524,13.544,0.863c0.858,0.066,0.854-1.272,0-1.333c-7.392-0.56-14.793-1.018-22.208-1.159
                      c-6.342-0.121-12.691,0.017-19.03,0.231c-4.521,0.155-9.044,0.337-13.572,0.515C49.877,78.273,49.872,79.606,50.729,79.577
                      L50.729,79.577L50.729,79.577z"/>
                    <path fill="#2F2E43" d="M50.729,94.653c7.846-0.301,15.695-0.621,23.546-0.737c7.027-0.106,14.059-0.013,21.082,0.307
                      c4.678,0.214,9.351,0.511,14.023,0.867c0.858,0.065,0.853-1.272,0-1.333c-7.826-0.592-15.662-1.032-23.508-1.166
                      c-7.032-0.123-14.063,0-21.09,0.223c-4.683,0.149-9.365,0.329-14.048,0.513c-0.852,0.029-0.856,1.363,0,1.331l0,0L50.729,94.653z"
                      />
                    <path fill="#2F2E43" d="M50.729,109.727c7.135-0.275,14.261-0.712,21.39-1.045c5.891-0.276,11.781-0.369,17.676-0.174
                      c4.415,0.146,8.826,0.424,13.229,0.756c0.856,0.066,0.853-1.271,0-1.333c-7.106-0.536-14.224-0.915-21.355-0.862
                      c-5.902,0.044-11.792,0.388-17.683,0.702c-4.417,0.235-8.836,0.449-13.257,0.623C49.877,108.425,49.872,109.76,50.729,109.727
                      L50.729,109.727L50.729,109.727z"/>
                    <path fill="#2F2E43" d="M50.729,124.802c9.129-0.35,18.267-0.5,27.405-0.447c9.137,0.053,18.125,0.306,27.174,0.763
                      c5.153,0.258,10.296,0.583,15.441,0.973c0.857,0.066,0.853-1.27,0-1.333c-9.111-0.688-18.236-1.172-27.369-1.456
                      c-9.134-0.284-18.125-0.356-27.188-0.235c-5.158,0.07-10.314,0.204-15.468,0.403c-0.853,0.032-0.857,1.365,0,1.333l0,0H50.729z"/>
                  </g>
                </g>
                <g id="arm-out">
                  <path id="shoulder_1_" fill="#6C63FF" d="M213.484,95.585l9.212-0.404c3.988,0.303,7.597,2.471,9.732,5.852
                    c6.507,10.288,18.683,31.971,7.812,34.942c-14.329,3.915-21.272-17.876-21.272-17.876L213.484,95.585L213.484,95.585z">
                      <animate  attributeName="d" dur="2s" repeatCount="indefinite"
                          values="M213.484,95.585l9.212-0.404c3.988,0.303,7.597,2.471,9.732,5.852
                    c6.507,10.288,18.683,31.971,7.812,34.942c-14.329,3.915-21.272-17.876-21.272-17.876L213.484,95.585L213.484,95.585z;
                    M213.484,95.585l9.212-0.404c3.988,0.303,8.471,2.153,8.638,6.153
                    c-1.096,14.301-19.213,27.195-30.084,30.167c-5.5-1.625-3.875-6.875-3.875-6.875L213.484,95.585L213.484,95.585z;
                            M213.484,95.585l9.212-0.404c3.988,0.303,7.597,2.471,9.732,5.852
                    c6.507,10.288,18.683,31.971,7.812,34.942c-14.329,3.915-21.272-17.876-21.272-17.876L213.484,95.585L213.484,95.585z"
                          keyTimes="0; 0.5; 1" />
                  </path>
                  <path id="pen_1_" fill="#2F2E43" d="M204.996,103.423l22.55-16.419c0.266-0.195,0.639-0.134,0.834,0.133l0.548,0.75
                    c0.194,0.265,0.133,0.64-0.132,0.835l-22.551,16.417l-2.989,1.291l-0.227-0.312L204.996,103.423z">
                      <animate  attributeName="d" dur="2s" repeatCount="indefinite"
                          values="M204.996,103.423l22.55-16.419c0.266-0.195,0.639-0.134,0.834,0.133l0.548,0.75
                    c0.194,0.265,0.133,0.64-0.132,0.835l-22.551,16.417l-2.989,1.291l-0.227-0.312L204.996,103.423z;
                            M153.354,102.41l15.574-23.142c0.183-0.274,0.555-0.344,0.829-0.16l0.771,0.518
                    c0.273,0.182,0.344,0.556,0.162,0.83l-15.576,23.14l-2.367,2.235l-0.319-0.215L153.354,102.41z;
                            M204.996,103.423l22.55-16.419c0.266-0.195,0.639-0.134,0.834,0.133l0.548,0.75
                    c0.194,0.265,0.133,0.64-0.132,0.835l-22.551,16.417l-2.989,1.291l-0.227-0.312L204.996,103.423z"
                          keyTimes="0; 0.5; 1"/>
                  </path>
                  <path id="hand_1_" fill="#F3A3A6" d="M210.327,102.05c-1.892-2.719-1.958-5.922-0.155-7.151
                    c1.808-1.232,4.808-0.019,6.7,2.7c0.771,1.075,1.261,2.327,1.428,3.641l7.868,11.631l-5.77,3.657l-7.143-11.854
                    C212.069,104.048,211.064,103.146,210.327,102.05L210.327,102.05L210.327,102.05z">
                    <animate  attributeName="d" dur="2s" repeatCount="indefinite"
                        values="M210.327,102.05c-1.892-2.719-1.958-5.922-0.155-7.151
                    c1.808-1.232,4.808-0.019,6.7,2.7c0.771,1.075,1.261,2.327,1.428,3.641l7.868,11.631l-5.77,3.657l-7.143-11.854
                    C212.069,104.048,211.064,103.146,210.327,102.05L210.327,102.05L210.327,102.05z;
                    M157.815,99.324c-2.708-1.908-3.865-4.895-2.592-6.667c1.277-1.776,4.511-1.662,7.22,0.246
                    c1.093,0.746,1.98,1.756,2.587,2.934l11.372,8.239l-4.171,5.409l-10.767-8.696C160.136,100.606,158.883,100.102,157.815,99.324
                    L157.815,99.324L157.815,99.324z;
                          M210.327,102.05c-1.892-2.719-1.958-5.922-0.155-7.151
                    c1.808-1.232,4.808-0.019,6.7,2.7c0.771,1.075,1.261,2.327,1.428,3.641l7.868,11.631l-5.77,3.657l-7.143-11.854
                    C212.069,104.048,211.064,103.146,210.327,102.05L210.327,102.05L210.327,102.05z"
                        keyTimes="0; 0.5; 1" />
                  </path>
                  <polygon id="arm_1_" fill="#6C63FF" points="214.983,111.454 221.481,105.471 234.332,117.437 222.382,125.605">
                  <animate  attributeName="points" dur="2s" repeatCount="indefinite"
                        values="214.983,111.454 221.481,105.471 234.332,117.437 222.382,125.605;
                        165.407,106.569 169.467,98.724 201.875,112.625 193.375,125.125;
                          214.983,111.454 221.481,105.471 234.332,117.437 222.382,125.605"
                        keyTimes="0; 0.5; 1" />
                  </polygon>
                </g>
              </svg>

            </div>
          </div>

          <div class="dde-tx-holder flex-center">
            <div class="dde-text">
                <h1>design</h1>
                <p>Style</p>
                <p>User Flow</p>
            </div>
          </div>

        </div> <!-- end dde-head -->

        <main class="secondary-content">

          <!-- <wa-ads-desktop></wa-ads-desktop> -->

          <section>
            <p>
            There are two distinct aspects of the product design; visual design and system design. Visual design concentrates on the aesthetic elements of a product, such as layout, color schemes, typography, and imagery. It aims to enhance usability and improve the user experience with attractive layouts and design elements. System design, on the other hand, involves the process of defining the architecture, modules, interfaces, and data for a system to satisfy specified requirements. It is more concerned with the overall structure and functionality rather than the visual aspects. While system design lays the foundation for a product’s functionality, visual design brings it to life by creating an emotional connection with the users.
            </p>
          </section>

          <section>
            <h2 class="dde-heading">Visual Design</h2>
            <p>
            When it comes to website design, several aspects play a crucial role in creating an effective and user-friendly online presence. 
            </p>
            <ul>
              <li>
                <p><strong>Style: </strong>
                representing your product with a suitable theme.
                </p>
              </li>
              <li>
                <p><strong>Simplicity: </strong>
                creating a clean and clear interface to improve usability.
                </p>
              </li>
              <li>
                <p><strong>Consistency: </strong>
                emanating your brand uniformly across the website.
                </p>
              </li>
              <li>
                <p><strong>Responsive: </strong>
                displaying your website gracefully on different devices and screen sizes.
                </p>
              </li>
              <li>
                <p><strong>Animations: </strong>
                subtle animations to improve the user experience.
                </p>
              </li>
            </ul>

            <p>
            A great website design isn’t just about aesthetics; it’s about creating a seamless experience for your audience.
            </p>


            <h3>Design Styles</h3>
            <p>
            Website style is important because it impacts how visitors perceive your site and it reflects the brand’s image and identity. Learn more about a couple of these website styles:
            </p>

             
            <div class="display-section">
              <h4>Hero Image: Showcasing your product…</h4>
                <div class="display-images">
                 
                    <img class="desktop-img mobile-none" src="/images/display/display-prod-web.png" alt="hero desktop display">
                    <img class="desktop-img desktop-none" src="/images/display/display-prod-web-min.png" alt="hero desktop display">
            
                    <img class="mobile-img mobile-none" src="/images/display/display-prod-mobile.png" alt="hero mobile display">
                    <img class="mobile-img desktop-none" src="/images/display/display-prod-mobile-min.png" alt="hero mobile display">
              
                </div>
                <p>
                A hero image sets the tone, communicates your brand’s story, and draws users into your website immediately. This style is characterised by:
                </p>
                <ul>
                    <li>
                      <p><strong>Enhanced Visual Appeal: </strong>
                      A full-page hero image makes a strong first impression by capturing visitors’ attention and creating an emotional connection.
                      </p>
                    </li>
                    <li>
                      <p><strong>Increased Conversion Rates: </strong>
                      A well-designed hero image can effectively communicate your message or call to action.
                      </p>
                    </li>
                    <li>
                      <p><strong>Better Storytelling: </strong>
                      A hero image serves as a visual narrative, immersing visitors and engaging them.
                      </p>
                    </li>
                </ul>
                <p>
                Hero images create engaging experiences and are particularly effective for businesses with strong visual identities, such as fashion or travel brands.
                </p>
            </div>

            <div class="display-section">
               <h4>Dark Theme: Creating the right mood…</h4>
               <a href="https://webdevpathway.hashtag-webapp.com" target="_blank">
                  <div class="display-images">
                  
                      <img class="desktop-img mobile-none" src="/images/display/display-dark-web.png" alt="dark theme desktop display">
                      <img class="desktop-img desktop-none" src="/images/display/display-dark-web-min.png" alt="dark theme desktop display">
                  
                  
                      <img class="mobile-img" src="/images/display/display-dark-mobile.png" alt="dark theme mobile display">
                  
                  </div>
                </a>
                <p>
                Dark-themed websites have gained popularity for their elegance, sophistication, and unique visual appeal. Some key characteristics of these intriguing designs include 
                </p>

                <ul>
                    <li>
                      <p><strong>Dramatic Overtones: </strong>
                      Dark designs evoke drama and intrigue. 
                      </p>
                    </li>
                    <li>
                      <p><strong>Softer, Soothing Tones: </strong>
                      Despite their dark appearance, these websites often use softer color tones. These muted shades create a calming effect and enhance the overall user experience.
                      </p>
                    </li>
                    <li>
                      <p><strong>Negative space: </strong>
                      Dark designs often embrace ample negative space. This helps focus attention on essential elements and prevents the page from feeling cluttered.
                      </p>
                    </li>
                    <li>
                      <p><strong>Stark Contrast: </strong>
                      Dark themes rely on high contrast between elements. Bold typography, vivid images, and well-defined lines stand out against the dark backdrop.
                      </p>
                    </li>
                    <li>
                      <p><strong>Practical Benefits: </strong>
                      In addition to looking stylish, they also reduce eye strain, especially when browsing in low-light conditions, and can even save battery life on mobile devices.
                      </p>
                    </li>
                </ul>

                <p>
                Dark themed styles can be particularly effective for portfolios, creative agencies, and brands aiming to convey a sense of exclusivity.
                </p>
            </div>


            <div class="display-section">
                <h4>Minimalist: An easy relaxing experience…</h4>
                <a href="https://www.hashtag-metoo.com" target="_blank">
                  <div class="display-images">
                  
                      <img class="desktop-img mobile-none" src="/images/display/display-health-web.png" alt="minimalist desktop display">
                      <img class="desktop-img desktop-none" src="/images/display/display-health-web-min.png" alt="minimalist desktop display">
                  
                      <img class="mobile-img" src="/images/display/display-health-mobile.png" alt="minimalist mobile display">
                
                  </div>
                </a>
                <p>
                Minimalism is a deliberate approach to design that prioritizes functionality and user experience.
                </p>

                <ul>
                  <li>
                    <p><strong>Focus on Essential Content: </strong>
                    Minimalist websites eliminate unnecessary elements. Only crucial information, calls-to-action, and key messages are retained.
                    </p>
                  </li>
                  <li>
                    <p><strong>High-Quality Visuals: </strong>
                    High-resolution images, videos, and graphics create an immediate impact.
                    </p>
                  </li>
                  <li>
                    <p><strong>Clear Typography: </strong>
                    Simple, clean, and legible typefaces are preferred for an improved user experience.
                    </p>
                  </li>
                  <li>
                    <p><strong>Negative White Space: </strong>
                    Negative space surrounds design elements create a sense of balance and clarity. 
                    </p>
                  </li>
              </ul>
 
                <p>
                Designed correctly minimalism styles are elegant and get effective results.
                </p>
            </div>
            <p>
            A well-designed website can increase user engagement, create a good first impression, and improve usability, which can lead to better retention and conversion rates. 
            </p>

          </section>
          <!-- <wa-ads-mobile></wa-ads-mobile> -->
          <section>
            <h2 class="dde-heading">System Design</h2>
            <p>
            System design in website development refers to the process of defining the architecture, components, modules, interfaces, and data for a system to satisfy specified requirements. It involves planning the structure of the website, how it will function, and how it will be implemented technically to ensure reliability, scalability, and performance. 
            </p>
            <h3>Features</h3>
            <p>
            When it comes to system design, several crucial aspects contribute to creating an efficient website.
            </p>
            <ul>
                <li>Architecture (hardware, technologies, application and communication)</li>
                <li>Components </li>
                <li>User Flow</li>
                <li>Storage and Databases</li>
            </ul>

            <h3>Benefits</h3>
            <p>
            Good system design for websites offers several benefits, including:
            </p>
            <ul>
                <li>
                  <p><strong>Consistency: </strong>
                  Ensures uniformity across different parts of the website.
                  </p>
                </li>
                <li>
                  <p><strong>Efficiency: </strong>
                  Speeds up the design process and reduces manual adjustments.
                  </p>
                </li>
                <li>
                  <p><strong>Scalability: </strong>
                  Makes it easier to grow and expand the website.
                  </p>
                </li>
                <li>
                  <p><strong>User Experience: </strong>
                  Improves navigation and interaction for users.
                  </p>
                </li>
                <li>
                  <p><strong>Cost Reduction: </strong>
                  Lowers development costs by eliminating unnecessary components.
                  </p>
                </li>
                <li>
                  <p><strong>SEO Advantage: </strong>
                  A well-designed website can positively impact SEO efforts.
                  </p>
                </li>
            </ul>

            <p>
            System design is about creating coherent, efficient systems that meets the specific requirements for your website.
            </p>
          </section>


          <section>
            <hr>
            <br>
            <p>Reach out to for more information on designing your website.</p>
            <a class="btn contact-btn" href="/contact" @click="${anchorRoute}">Contact us</a>
          </section>
        </main>
        
        <main-app-footer></main-app-footer>
      </body> 


    `

    render(template, App.rootEl)
  }
}

export default new DevPlayView()