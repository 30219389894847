// console.log('app connected')
import App from './App.js'

//components
import './components/main-app-header'
import './components/main-app-footer'

import './components/wa-ads-mobile'
import './components/wa-ads-desktop'

import './components/c-error'
import './components/c-success'
import './components/c-waiting'

//Styles
import './scss/styles.scss'


document.addEventListener('DOMContentLoaded', () => {
  App.init()
})

window.addEventListener("resize", () => {
  App.resizeListener()
})