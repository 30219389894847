class Validator {

  init(){
    this.inputErrors = null
  }

  validateEmail(emailInput){
    const mailFormat = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if(emailInput.value.match(mailFormat)){
      console.log('mail format is good')
      return true
    } else {
      console.log('mail format is NOT good')
      this.addWarning(emailInput, '*Please enter a valid email')
      return false
    }
  }

  clearWarning(e){
    e.preventDefault()
    const myInput = e.target
    if (myInput.classList.contains('input-warning')){
      myInput.classList.remove('input-warning')
      myInput.nextElementSibling.innerHTML = ""
    }
  }

  addWarning(input, msg){
    const warnText = input.nextElementSibling
    if(input.classList.contains('input-warning')){
      return
    } else {
      input.classList.add('input-warning')
      warnText.innerHTML = msg
    }
  }

  safeCheck(input){
    //^(?:(?=[\p{Script=Arabic}A-Za-z])\p{L}|\s)+$
    var reg=/[^a-zA-Z0-9 \u0600-\u06FF\!\@\#\$\%\^\*\_\=\:\&\'\.\,\?\"\r\n]+/;
    //onst reg = /^(?:(?=[\p{Script=Arabic}A-Za-z])\p{L}|\s)+$/u;
    console.log(reg.test(input.value))
    if(reg.test(input.value)){              
      this.addWarning(input, '*Please avoid special characters')
      return false;
    }  else {
      return true
    }
  }



// https://zzzcode.ai/javascript/code-generator
  convertTexttoHtml(text){
      return text.replace(/&/g, '&amp;')
                 .replace(/</g, '&lt;')
                 .replace(/>/g, '&gt;')
                 .replace(/"/g, '&quot;')
                 .replace(/'/g, '&#039;');
  }
  // sample use    
  // let textWithSymbols = 'This is <b>bold</b> & <i>italic</i> text.';
  // let htmlCode = convertTextToHtml(textWithSymbols);
  // console.log(htmlCode);


// ----other versions -----
// var encoded = value.replace(/[^A-Za-z0-9]/g, function(i) {
//   return '&#' + i.charCodeAt(0) + ';';
// });


// htmlFriendly = jsFriendly.replace( /[^\w\s]/gi, 
//     function (char) { return `&#${char.charCodeAt(0)};`; }
// );


}

export default new Validator()