//import the website pages
import fourOFourView from './views/404'
import homeView from './views/1home'

import designView from './views/2Design'
import developView from './views/3Develop'
import experienceView from './views/4Experience'

import contactView from './views/5contact'
import termsView from './views/6terms'
import privacyView from './views/7privacy'


import testView from './views/ztest'


//set the url names 
const routes = {
	'404' : fourOFourView,
	'/': homeView,	

	'/design': designView,
	'/develop': developView,
	'/experience': experienceView,

	'/contact': contactView,
	'/terms': termsView,
	'/privacy': privacyView,

	'/ztest': testView,
}

class Router {
	constructor(){
		this.routes = routes
		this.lastRoute = null
	}
	
	init(){
		const newRoute = window.location.pathname
		if (this.lastRoute == newRoute){ return } 
		else {
			this.route(newRoute)
			this.lastRoute = newRoute
		}
		window.addEventListener('popstate', () => {
			this.route(window.location.pathname)
		})
	}
	
	route(fullPathname){
		const pathname = fullPathname.split('?')[0]
		const route = this.routes[pathname]
		if(route){ this.routes[window.location.pathname].init() }
		else{ this.routes['404'].init()	}
		//reset top of page
		const page = document.getElementById("root")
		const scrollPos = page.scrollTop;
		if (scrollPos > 20) {
			page.scrollTo(0, 0)
		}
	}

	gotoRoute(pathname){
		window.history.pushState({}, pathname, window.location.origin + pathname);
		this.route(pathname)
	}	
}

const AppRouter = new Router()
export default AppRouter

export function gotoRoute(pathname){
	AppRouter.gotoRoute(pathname)
}

export function anchorRoute(e){
	e.preventDefault()	
	const pathname = e.target.closest('a').pathname
	AppRouter.gotoRoute(pathname)
}
