import {LitElement, html, css} from 'lit'
import {gotoRoute} from '../Router'
import App from '../App'

customElements.define('main-app-header', class AppHeader extends LitElement {
  constructor(){
    super()    
    this.attachShadow({ mode: "open" })
  }

  static get properties(){
    return {
      pagename: {
        type: String
      }
    }
  }

  firstUpdated(){
    super.firstUpdated()
    this.navActiveLinks()
    this.header = document.querySelector('main-app-header')
    App.setSiteWidth(this.header)
  }


  navActiveLinks(){	
    const currentPath = window.location.pathname
    const navLinks = this.shadowRoot.querySelectorAll('.app-side-menu-items a')
    if(currentPath == '/'){
      const homeIcon = this.shadowRoot.getElementById('not-home')
      homeIcon.style.display = "none"
    }
    navLinks.forEach(navLink => {
      if(navLink.href.slice(-1) == '#') return
      if(navLink.pathname === currentPath){			
        navLink.classList.add('active')
      } 
    })
  }

  // isMobile(){
  //   let viewportWidth = window.innerWidth
  //   if(viewportWidth <= 768){ return true }
  //   else{ return false }
  // }


  //open side menu (clicking & scrolling is prevented)
  hamburgerClick(){  
    const appMenu = this.shadowRoot.getElementById('ps-drawer')
    const holder = this.shadowRoot.getElementById('drawer-backdrop-holder')
    const backdrop = this.shadowRoot.getElementById('drawer-backdrop')
    holder.style.width = "100%"
    backdrop.style.backgroundColor = "rgb(0,0,0,0.5)"
    // this makes the menu cover the scroll bar
    let rightValue = window.innerWidth - App.rootEl.clientWidth
    appMenu.style.right = "-" + rightValue + 'px';
  }


  // Close side menu 
  closeMenuClick(){  
    const appMenu = this.shadowRoot.getElementById('ps-drawer')
    const holder = this.shadowRoot.getElementById('drawer-backdrop-holder')
    const backdrop = this.shadowRoot.getElementById('drawer-backdrop')
    appMenu.style.right = "-100%"
    backdrop.style.backgroundColor = "rgb(0,0,0,0.0)"
    // wait half a second
        setTimeout(function () {
          holder.style.width = "0"
      }, 400);
  }

  // close menu on backdrop click
  backDropClick(e){
    const backdrop = this.shadowRoot.getElementById('drawer-backdrop')
    // if (e === backdrop){ this.closeMenuClick() }
    this.closeMenuClick()
  }
  

  menuClick(e){
    e.preventDefault()
    const currentPath = window.location.pathname
    const pathname = e.target.closest('a').pathname
    if(currentPath == pathname){return}
    else { 
      const mobile = App.isMobile()
      if (mobile == true){
        this.closeMenuClick()
        setTimeout(function () {
          gotoRoute(pathname)
        }, 300);
      }
      else { gotoRoute(pathname) }
    }
  }

  static styles = css`
  * { box-sizing: border-box; margin: 0px; padding: 0px; }
      .app-header { 
        width: 100%;
        position: relative; z-index: 99; padding: 0px 15px;
        display: flex; align-items: center; justify-content: space-between;
        backdrop-filter: blur(5px); 
        background-color:rgba(255, 255, 255, 0.285);
      }
      #not-home { text-decoration: none; font-size: 1.5em; font-weight: bold;
        font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; }
      .flex-center { display: flex; align-items: center; justify-content: center; }
      .logo { margin: 0 10px; }
      #burger-div { height: 60px; padding: 0.7em; cursor: pointer; }

        #drawer-backdrop-holder{
          position: absolute;
          top: 0;
          right: 0;
          width: 0;  
          height: 100vh;
          z-index: 10;
        }
        #drawer-backdrop{
          position: relative;
          width: 100%;  
          height: 100%;
          background-color: rgb(0,0,0,0.0);
          transition: background-color .5s ease-out;
        }


      #nav-wrapper {
        position: absolute;
        top: 0;
        right: 0;
        padding:  3vh 3vh;
        width: 100%;
      }
      .close-menu {
        width: 40px;
        padding: .3em;
        position: absolute;
        cursor: pointer;
        right: 15px;
        top: 15px;
      }
      .app-side-menu-items { text-align: right; }

      .app-side-menu-items img { margin: auto; width: 70px; margin: 3vh 0; } 
      .app-side-menu-items a {
        display: block;
        padding: .5em;
        text-decoration: none;
        font-family: 'Arial';
        font-size: 1.2em;
        color: var(--grey-600);
        text-align: left;
      } 
      .app-side-menu-items a:hover { color: var(--header-hover); }
      .app-side-menu-items a.active { color: var(--header-active); }



      /* mobile styles */
      @media all and (max-width: 768px){   
        .app-header { height: 60px; }
        #ps-drawer {
          position: absolute;
          z-index: 99;
          height: 100vh;
          width: 50vw;
          padding: 0 5vw;
          transition: .5s ease-out;
          right: -100%;
          top: 0;
        }

        #nav-wrapper { height: 100vh; padding: 1vh 3vh; overflow: auto; background-color: white; box-shadow: 0px 0px 10px rgba(0,0,0,0.3); }
        .mobile-hide { display: none; }
        .app-side-menu-items { margin-top: 50px; }
        .app-side-menu-items a.active { border-left: 1px solid var(--header-border); }
        #drawer-header { width: 100%; margin-top: 20px;}
      }

      @media all and (max-width: 500px){ 
        #ps-drawer { width: 80vw; } 
      }


      /* desktop styles */
      @media all and (min-width: 769px){  
        .app-header { height: var(--header-height); }
        .logo { height: 50px;}
        #ps-drawer {
          position: relative;
          height: 70px;
          width: 70vw;
        }


        .app-side-menu-items a { display: inline; }
        .desktop-hide { display: none; }
        #title-div { margin-left: 30vw; width: 47vw; align-items: left; justify-content: left;}
        .logo-home { width: 80px;  }
        
      }
  `

  render(){    
    return html`


    <header class="app-header">
      <div class="header-items">
        <a id="not-home" class="flex-center" href="/" @click="${this.menuClick}">
        <img class='logo' height="40px" alt="Hashtag Logo" src='/images/wa3-logo.svg'/> WebApp</a>
      </div>

      <div class="header-items">      
        <svg id="burger-div" class="desktop-hide" @click="${this.hamburgerClick}" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            height="100px" viewBox="0 0 100 100" enable-background="new 0 0 100 100" xml:space="preserve">
          <line fill="none" stroke="#C1C1C1" stroke-width="17" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="11.345" y1="15.44" x2="88.655" y2="15.44"/>
          <line fill="none" stroke="#C1C1C1" stroke-width="17" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="11.345" y1="50.722" x2="88.655" y2="50.722"/>
          <line fill="none" stroke="#C1C1C1" stroke-width="17" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="11.345" y1="86.001" x2="88.655" y2="86.001"/>
        </svg>

        <div id="drawer-backdrop-holder">
          <div id="drawer-backdrop" @click="${this.backDropClick}"></div>
        </div>
        <div id="ps-drawer">
        <div id="nav-wrapper"> 
          <svg class="close-menu desktop-hide"  @click="${this.closeMenuClick}" alt="close" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
              width="25px" viewBox="0 0 34.25 33.875" enable-background="new 0 0 34.25 33.875" xml:space="preserve">
            <path fill="#ad6cbf" d="M22.592,16.938L32.967,6.563c0.715-0.712,1.104-1.661,1.104-2.669c0-1.01-0.39-1.959-1.104-2.673
              c-1.43-1.426-3.918-1.427-5.344,0L17.248,11.596L6.875,1.22c-1.428-1.427-3.918-1.427-5.344,0c-1.475,1.474-1.475,3.87,0,5.343
              l10.373,10.376L1.531,27.314c-0.715,0.713-1.107,1.662-1.107,2.67c0,1.012,0.393,1.959,1.107,2.674c1.426,1.426,3.916,1.426,5.344,0
              l10.373-10.377l10.375,10.377c0.715,0.711,1.662,1.104,2.674,1.104c1.008,0,1.955-0.394,2.67-1.104c1.473-1.476,1.473-3.872,0-5.344
              L22.592,16.938z"/>
          </svg>
          <nav class="app-side-menu-items">
            <a href="/" @click="${this.menuClick}">Home</a>
            <a href="/design" @click="${this.menuClick}">Design</a>
            <a href="/develop" @click="${this.menuClick}">Develop</a>
            <a href="/experience" @click="${this.menuClick}">Experience</a>
            <a href="/contact" @click="${this.menuClick}">Contact</a>
          </nav> 
        </div>
      </div>
        
      </div>



    </header>
  
    `
  }
})
