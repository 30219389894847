import App from '../App'
import {render, html} from 'lit/html.js'
import { anchorRoute } from '../Router'

class HomeView {
  init(){    
    // console.log('Home View.init')
    document.title = App.name + ' | Home'    
    this.render() 
    this.heroDiv = document.querySelector('.hero')
    this.heroDiv.addEventListener('mousemove', (e) => {
      this.animateHero(e)
    })

    this.scrollSize()
    App.equalizeHomeDivHeights()
  }


  animateHero(event){
    const heroImgs = document.querySelectorAll('#hero-img-logo, #hero-img-shadow, #hero-img-grid')
    const x = event.clientX;
    const y = event.clientY;
    // console.log(x, y)
    // find the middle
    const middleX = this.heroDiv.offsetHeight / 2;
    const middleY = this.heroDiv.offsetWidth / 2;
    // console.log(middleX, middleY)
    const offsetX = ((x - middleX) / middleX) * 25; 
    const offsetY = ((y - middleY) / middleY) * 25;
    // console.log(offsetX, offsetY)
    heroImgs.forEach(element => {
      element.style.setProperty("--rotatex", -1 * offsetY + "deg")
      element.style.setProperty("--rotatey", offsetX + "deg")
    });
  }

  scrollSize(){
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry)=> {
        // console.log(entry)
        if (entry.isIntersecting){
          entry.target.classList.add('size-up')
        } else {
          entry.target.classList.remove('size-up')
        }
      })
    })
    const elements = document.querySelectorAll('.size-me')
    elements.forEach((el) => observer.observe(el));
  }



  render(){
    const template = html`

     
      <body>  
        <main-app-header pagename="${document.title}"></main-app-header>

        
        <main id="home-body">
          
          <div class="hero flex-center">

            <div class="hero-image-holder flex-center">
              <div id="hero-image-position">
                <img id="hero-img-grid" src="/images/home/wa-hero.svg" alt="grid design image">
                <img id="hero-img-shadow" src="/images/home/wa-heroc.svg" alt="logo shadow">
                <img id="hero-img-logo" src="/images/home/wa-herob.svg" alt="logo image">
              </div>
            </div>

            <div class="hero-text">
                <div class="hero-name">WebApp</div>
                <div class="hero-words">
                  <p id="word1">
                    <span id="a1">d</span><span id="a2">e</span><span id="a3">s</span><span id="a4">i</span><span id="a5">g</span><span id="a6">n</span><span id="a7">e</span><span id="a8">d</span>
                  </p>
                  <p id="word2">
                    <span id="b1">d</span><span id="b2">e</span><span id="b3">v</span><span id="b4">e</span><span id="b5">l</span><span id="b6">o</span><span id="b7">p</span><span id="b8">e</span><span id="b9">d</span>
                  </p>
                  <p id="word3">
                    <span id="c1">e</span><span id="c2">x</span><span id="c3">p</span><span id="c4">e</span><span id="c5">r</span><span id="c6">i</span><span id="c7">e</span><span id="c8">n</span><span id="c9">c</span><span id="c10">e</span><span id="c11">d</span> 
                  </p>
              </div>
            </div>

          </div>


          <section class="your">
            <h2><span class="hh-span">Custom Websites:</span> Designed for a unique user experience</h2>
            <p>Represent <span>your</span> brand and cater to <span>your</span> users with a custom website.</p>
              <ul> 
                <li>Branding through to deployment for <span>your</span> product</li>
                <li>Turn <span>your</span> designs into a website</li>
                <li>Backends or databases for <span>your</span> needs</li>
                <li>Automated emails to <span>your</span> clients</li>
                <li><span>Your</span> own custom payment system</li>
                <li>Cloud based solutions for <span>your</span> website</li>
                <li>Training for <span>your</span> website code base</li>
              </ul>
            <p>Whether you require a fully designed and developed solution or part thereof, <span id="webapp">${App.name}</span> can cater to <span>your</span> needs.</p>
          </section>


          <section id="division" class="clearfix">
            <div class="float">
                <h3>design</h3>
                  <div class="float-img flex-center">
                    <img class="size-me" src="/images/draw/wa-design-svg.svg" alt="design">
                  </div>
                  <div class="float-text">
                    <p>
                    Website design involves visual and structural planning. Your clients are better serviced, when the platform is designed for your product or service.
                    </p>
                    <a class="btn read-more" href="/design" @click="${anchorRoute}">Learn more</a>
                    <hr>
                  </div>
                  
              </div>
              <div class="float">
                <h3>develop</h3>
                  <div class="float-img flex-center">
                    <img class="size-me" src="/images/draw/wa-develop-svg.svg" alt="develop">
                  </div>
                  <div class="float-text">
                    <p>
                    Developing a custom website ensures a better user experience.  Furthermore, the system will be more efficient catering to your specific needs.
                    </p>
                    <a class="btn read-more" href="/develop" @click="${anchorRoute}">Learn more</a>
                    <hr>
                  </div>
                  
              </div>
              <div class="float">
                <h3>experience</h3>
                  <div class="float-img flex-center">
                    <img class="size-me" src="/images/draw/wa-ux-svg.svg" alt="experience">
                  </div>
                  <div class="float-text">
                    <p>
                    The best user experience can only be attained after testing.  A well tested product includes functionality testing, user flow testing and UX testing.
                    </p>
                    <a class="btn read-more" href="/experience" @click="${anchorRoute}">Learn more</a>
                    <hr>
                  </div>
                  
              </div>
          </section>

          <section class="your">
            <h2><span class="hh-span">The Benefits</span></h2>
            <p>
              Custom websites offer several advantages over generic templates or pre-built solutions. 
            </p>
            <ul>
              <li><span>Uniqueness: </span>
                  When you opt for a custom website, it’s like having a tailor-made suit. Unlike using website builders or content management systems (CMS), where you’re limited by preset themes, a custom-built site is unique to your vision. Each element is created according to your requirements, allowing your website to stand out.  
                </li>
                <li><span>Speed: </span>
                    Many website builders and CMS platforms come with built-in functionality, but this can lead to unnecessary bloatware. Custom websites allow you to avoid this excess baggage. By streamlining features and optimizing code, your site loads faster.  And slow loading times can drive users away.
                </li>
                <li><span>Brand Alignment: </span>
                    A custom website aligns perfectly with your brand identity. From colors and fonts to layout and user experience, everything can be tailored to match your business.  Consistent branding can improve customer recognition and improve trust.
                </li>
                <li><span>Better User Experience: </span>
                    Custom sites are designed with your specific audience in mind. You can create intuitive navigation, personalized interactions, and seamless functionality. A great user experience can improve engagement and conversion rates.
                </li>
                <li><span>Competitive Edge: </span>
                    A custom website sets you apart from competitors. It showcases your unique selling points, differentiators, and industry leadership. When your site is unique and stands out, it attracts more visitors and increases revenue. 
                </li>
            </ul>
            <p>
                In summary, a custom website offers flexibility, performance, and a tailored experience that generic solutions can’t match. Consider investing in one to elevate your online presence! 
            </p>
            <a class="btn contact-btn" href="/contact" @click="${anchorRoute}">Contact us</a>

          </section>
        </main>
        <main-app-footer></main-app-footer>
      </body> 

    `

    render(template, App.rootEl)
  }
}

export default new HomeView()